import { Action, ActionType } from "../Actions/ActionType";

const initialState = {
  audioku: {},
  surat: {},
  doaku: [],
  loadDoa: false,
  loadSurat: false,
};

export const GetAudioReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.GET_AUDIO:
      return {
        audioku: action.audioku,
      };
    default:
      return state;
  }
};

export const GetSuratReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_SURAT_TRUE:
      return {
        loadSurat: true,
      };
    case ActionType.LOAD_SURAT_FALSE:
      return {
        loadSurat: false,
      };
    case ActionType.GET_SURAT:
      return {
        surat: action.surat,
      };
    default:
      return state;
  }
};

export const GetDoaReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_DOA_TRUE:
      return {
        loadDoa: true,
      };
    case ActionType.LOAD_DOA_FALSE:
      return {
        loadDoa: false,
      };
    case ActionType.GET_DOA:
      return {
        doaku: action.payload,
      };
    default:
      return state;
  }
};

// export const GetDetailDoaReducer = (state = initialState, action: Action) => {
//   switch (action.type) {
//     case ActionType.GET_DETAIL_DOA:
//       return {
//         detailDoa: action.detailDoa,
//       };
//     default:
//       return state;
//   }
// };
