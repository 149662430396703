import { Dispatch } from "redux";
import { ActionType, Action } from "./ActionType";
import axios from "axios";
import { GET_SURAT, getDoa } from "../../Services/SetupAxios";

export const getAudio = (audi: any) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = audi;
    dispatch({
      type: ActionType.GET_AUDIO,
      audioku: response,
    });
  } catch (error) {
    console.error("Gagal mengambil data:", error);
  }
};

export const getSuratApi = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: ActionType.LOAD_SURAT_TRUE });
    const response = await axios.get(GET_SURAT);
    dispatch({
      type: ActionType.GET_SURAT,
      surat: response.data,
    });
    dispatch({ type: ActionType.LOAD_SURAT_FALSE });
  } catch (error) {
    dispatch({ type: ActionType.LOAD_SURAT_FALSE });
    console.error("Gagal mengambil data:", error);
  }
};

export const getDataDoa = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: ActionType.LOAD_DOA_TRUE });
    const resp = await getDoa();
    if (resp.status === 200) {
      dispatch({
        type: ActionType.GET_DOA,
        payload: resp.data.data,
      });
      dispatch({ type: ActionType.LOAD_DOA_FALSE });
    }
  } catch (error) {
    dispatch({ type: ActionType.LOAD_DOA_FALSE });
    console.error("Gagal mengambil data Doa:", error);
  }
};

// export const getDetailDataDoa = () => {
//   return async (dispatch: Dispatch<Action>) => {
//     try {
//       const res = await getDetailDoa();
//       if (res.status === 200) {
//         dispatch({
//           type: ActionType.GET_DETAIL_DOA,
//           detailDoa: res.data,
//         });
//       }
//     } catch (error) {
//       console.error("Gagal mengambil data Doa:", error);
//     }
//   };
// };
