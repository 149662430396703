import axios from "axios";

export const BASE_URL = "https://equran.id/api/v2/";
export const GET_SURAT = BASE_URL + "surat";
export const GET_DETAIL_SURAT = GET_SURAT + ":idsurat";
export const URL_DOA = "https://equran.id/api/";
export const GETDOA = URL_DOA + "doa";
export const GET_DETAIL_DOA = (iddoa: any) => `${GETDOA}/${iddoa}`;
export function getSurat() {
  return axios.get(GET_SURAT);
}

export function getDetailSurat() {
  return axios.get(GET_DETAIL_SURAT);
}

export function getDoa() {
  return axios.get(GETDOA);
}

export function getDetailDoa(iddoa: any) {
  return axios.get("https://equran.id/api/doa/" + iddoa);
}
