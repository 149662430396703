export enum ActionType {
  GET_AUDIO = "GET_AUDIO",
  GET_SURAT = "GET_SURAT",
  GET_DOA = "GET_DOA",
  GET_DETAIL_DOA = "GET_DETAIL_DOA",
  LOAD_DOA_TRUE = "LOAD_DOA_TRUE",
  LOAD_DOA_FALSE = "LOAD_DOA_FALSE",
  LOAD_SURAT_TRUE = "LOAD_SURAT_TRUE",
  LOAD_SURAT_FALSE = "LOAD_SURAT_FALSE",
}

interface ActionGetAudio {
  type: ActionType.GET_AUDIO;
  audioku: any;
}

interface ActionGetSurat {
  type: ActionType.GET_SURAT;
  surat: any;
}

interface ActionGetDoa {
  type: ActionType.GET_DOA;

  payload: any;
}

interface ActionGetDetailDoa {
  type: ActionType.GET_DETAIL_DOA;
  detailDoa: any;
}

interface ActionLoadDoaTrue {
  type: ActionType.LOAD_DOA_TRUE;
}

interface ActionLoadDoaFalse {
  type: ActionType.LOAD_DOA_FALSE;
}

interface ActionLoadSuratTrue {
  type: ActionType.LOAD_SURAT_TRUE;
}

interface ActionLoadSuratFalse {
  type: ActionType.LOAD_SURAT_FALSE;
}

export type Action = ActionGetAudio | ActionGetSurat | ActionGetDoa | ActionGetDetailDoa | ActionLoadDoaTrue | ActionLoadDoaFalse | ActionLoadSuratTrue | ActionLoadSuratFalse;
