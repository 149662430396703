import { Col } from "react-bootstrap";

const About = () => {
  return (
    <>
      <Col md={8}>
        <h2>Ini Halaman About</h2>
        <p>Website ini saya buat dengan tujuan supaya setiap muslim yang ingin membaca alquran secara online bisa lebih mudah dan bisa membaca dimanapun</p>
        <p>Selain itu, untuk mempermudah dalam membaca alquran saya juga menyediakan audio yang bisa diputar sesuai dengan surat yang sedang anda baca.</p>
        <p>Tidak hanya membaca alquran, website ini juga menampilkan doa sehari-hari yang bisa dipelajari</p>
      </Col>
    </>
  );
};

export default About;
