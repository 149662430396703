import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDoa } from "../Services/SetupAxios";

function HalamanDoa() {
  const [loadDoa, setLoadDoa] = useState<boolean>(false);
  const [dataDoa, setDataDoa] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getDataDoa();
  }, []);

  const getDataDoa = async () => {
    try {
      setLoadDoa(true);
      const resp = await getDoa();
      if (resp.status === 200) {
        setDataDoa(resp.data);
        setLoadDoa(false);
      }
    } catch (error) {
      setLoadDoa(false);
      console.error("Gagal mengambil data Doa:", error);
    }
  };

  console.log(dataDoa, "ini data doa");

  return (
    <>
      <Container>
        {loadDoa ? (
          <div className="text-center mt-5">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div>
            {dataDoa.map((d: any) => (
              <Col key={d.id} style={{ marginBottom: "20px" }}>
                <Card>
                  <Link to={`${d.id}/${d.nama.replace(/\s+/g, "-").toLowerCase()}`} className="text-decoration-none bg-success text-white p-2 fw-bold">
                    {d.id}. {d.nama}
                  </Link>
                </Card>
              </Col>
            ))}
          </div>
        )}
      </Container>
    </>
  );
}

export default HalamanDoa;
