import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import axios from "axios";

interface Doa {
  id: number;
  nama: string;
  ar: string;
  idn: string;
  tentang: string;
  tr: string;
}
function SingleDoa() {
  const { iddoa } = useParams<any>();
  const [detailDoa, setDetailDoa] = useState<Doa>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDataDoa = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`https://equran.id/api/doa/${iddoa}`);
        if (res.status === 200) {
          setDetailDoa(res.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Gagal mengambil data:", error);
      }
    };

    getDataDoa();
  }, []);

  console.log("ini detail doa", detailDoa);
  return (
    <>
      <Container>
        <Row>
          <Col>
            {loading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <div>
                <h2 className="text-center">{detailDoa?.nama}</h2>
                <hr />
                <div>
                  <p className="arab p-3 text-center" style={{ fontSize: "35px" }}>
                    بِسْمِ اللهِ الرَّحْمَنِ الرَّحِيْمِ
                  </p>
                </div>
                <hr />
                <div className="arab p-3" style={{ fontSize: "35px" }}>
                  {detailDoa?.ar}
                </div>
                <div style={{ fontSize: "22px" }}>
                  <p>{detailDoa?.tr}</p>
                  <b>Artinya: </b>
                  <br />
                  <p>{detailDoa?.idn}</p>
                  <b>Tentang {detailDoa?.nama}:</b>
                  <br />
                  <p>{detailDoa?.tentang}</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SingleDoa;
