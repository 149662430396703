import { useState, useEffect } from "react";
import { Container, Card, Row, Col, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getAudio } from "../Redux/Actions/ActionGetAudio";
import { useDispatch } from "react-redux";
import { getSurat } from "../Services/SetupAxios";

interface Surat {
  namaLatin: string;
  arti: string;
  jumlahAyat: number;
  tempatTurun: string;
  nomor: number;
}

function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { idsurat } = useParams();

  useEffect(() => {
    dispatch<any>(getAudio(idsurat));
    getSuratApi();
  }, [idsurat]);

  const getSuratApi = async () => {
    try {
      setLoading(true);
      const response = await getSurat();
      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Gagal mengambil data:", error);
    }
  };

  return (
    <>
      <Container>
        <div>
          {loading ? (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <Row>
              {data.map((s: Surat) => (
                <Col key={s.nomor} md={4} style={{ marginBottom: "20px" }}>
                  <Card>
                    <Card.Title className="text-center bg-light pt-2 pb-2 mb-2">
                      <Link to={`surat/${s.nomor}/${s.namaLatin.toLowerCase()}`} className="text-decoration-none text-dark">
                        Surat {s.namaLatin}
                      </Link>
                    </Card.Title>
                    <Card.Text className="text-center mb-4" style={{ height: "75px" }}>
                      Surat {s.namaLatin} Memiliki arti {s.arti} di dalam surat ini terdapat {s.jumlahAyat} Ayat
                    </Card.Text>

                    <Link to={`surat/${s.nomor}/${s.namaLatin.toLowerCase()}`} className="btn btn-success">
                      <b>Baca Sekarang</b>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Container>
    </>
  );
}

export default Home;
